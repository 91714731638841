import { Amplify, I18n } from "aws-amplify";
import { ThemeProvider } from "@aws-amplify/ui-react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import config from "./aws-exports";

import { dict } from "./languages"
import App from "./App";

import "./index.css";


(async () => {
  try {
    let ac;
    ac = localStorage.getItem('ac');

    if (ac === null) {
      console.log('loading ac.json from cloud')
      let url;

      url = 'https://theoneaboveall.s3.amazonaws.com/altair/public/ac.json'

      ac = await fetch(url, {cache: "no-store"})
        .then(function(response) {
          return response.json();
        }).catch(function(error) {
          console.error("error fetching ac.json", error);
          return null;
        });

      // console.log('ac', ac)
      if (ac !== null) localStorage.setItem('ac', JSON.stringify(ac))

    } else {
      // console.log('ac is available from localStorage')
    }
  } catch (e) {
    console.log('error', e)
  }
})();


const lookAndFeel = {
  lang: navigator.languages[0].split("-")[0],
  imgSrc: 'https://modelfactory.io/cdn/altair.png',
  mc: '#373A40',
  sc: '#19D3DA'
};

localStorage.setItem("altairDefaultLAF", JSON.stringify(lookAndFeel));


I18n.setLanguage(lookAndFeel.lang);
I18n.putVocabularies(dict);
Amplify.configure(config);

const theme = {
  name: 'multiCompanyCSS',
  tokens: {
    components: {
      button: {
         backgroundColor: { value: lookAndFeel.mc  },
         primary:{
           backgroundColor: { value: lookAndFeel.mc },
           color: {value: lookAndFeel.sc},
           _hover:{
             backgroundColor: { value: lookAndFeel.sc },
             color: {value: lookAndFeel.mc}
           }
         }
      },
      passwordfield: {
        button: {
          color: { value: lookAndFeel.mc },
          backgroundColor: { value: lookAndFeel.mc },
          _hover: {
            border:0,
            borderColor: {value: 'transparent'},
            backgroundColor: { value: lookAndFeel.sc },
            color: { value: lookAndFeel.mc },
          },
          _active: {
            backgroundColor: { value: lookAndFeel.mc },

            borderColor: {value: 'transparent'},

          },
          _focus: {
            color: { value:  lookAndFeel.mc  },
            borderColor: {value: 'transparent'},

          },
        },
      },
      fieldcontrol: {
        borderRadius: 0,
        border: 0,
        borderColor: {value: 'transparent'},
        color: { value: lookAndFeel.mc},
      },

    }
  }
}


const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <ThemeProvider theme={theme}>
    <HelmetProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </ThemeProvider>
);
