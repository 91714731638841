export const dict = {
  en: {
    'close_button': 'Cerrar',
    'date_label': 'Fecha',
    'ticket_type': 'Tipo de Ticket',
    'ticket_afectation': 'Afectación',
    'owner_id': 'ID del propietario',
    'ticket_files': 'Archivos',
    'db_status_label': 'Estatus',
    'customer_service_button_menu': 'Servicio al cliente',
    'new_comment_label': 'Nuevo comentario',
    'file_attached': 'Archivo adjunto',
    'ticket_history': 'Historial',
    'ticket_time': 'Hora',
    'send_button': 'Enviar',
    'table_head_cat': 'Categoría',
    'table_head_revenue_average': 'Promedio de ingresos',
    'table_head_gross_profit_average': 'Promedio de ganancia bruta',
    'categories_button_menu': 'Categorías',
    'cat_revenue_average': 'Promedio de ingresos',
    'cat_gross_profit_average': 'Promedio de ganancia bruta',
    'cat_table_title': 'Categoría',
    'table_head_sku': 'SKU',
    'table_head_store': 'Sucursal',
    'products_button_menu': 'Productos',
    'prod_gross_profit_average': 'Promedio de ganancia bruta',
    'prod_revenue_average': 'Promedio de ingresos',
    'prod_table_title': 'Producto',
    'manage_button': 'Administrar',
    'confirm_button': 'Confirmar',
    'stores_button_menu': 'Sucursales',
    'stores_gross_profit_average': 'Promedio de ganancia bruta',
    'stores_revenue_average': 'Promedio de ingresos',
    'table_title': 'Sucursal',
    'store_A': 'Sucursal A',
    'store_B': 'Sucursal B',
    'gross_profit_average': 'Promedio de ganancia bruta',
    'revenue_average': 'Promedio de ingresos',
    'categories_button': 'Categorías',
    'top_products_by_category': 'Top productos por categoría',
    'products_button': 'Productos',
    'product': 'producto',
    'table_company': 'Compañia',
    'table_price': 'Precio',
    'table_prev_price': 'Precio previo',
    'table_diff': 'Diferencia',
    'table_variable': 'Variable',
    'table_current': 'Actual',
    'table_simulation': 'Simulación',
    'table_current_price': 'Precio actual',
    'table_max_price': 'Precio máximo',
    'table_min_price': 'Precio mínimo',
    'table_current_cost': 'Costo actual',
    'demand_at': 'Demanda en',
    'save_button': 'Guardar',
    'empty_forecasts': 'Forecasts vacio',
    'empty_search_label': 'Busqueda vacia',
    'delete_selection': 'Borrar selección',
    'search_by_forecast': 'Buscar por forecast',
    'new_forecast_label': 'Nuevo Forecast',
    'form_subject': 'Asunto',
    'form_desc': 'Descripción',
    'new_ticket': 'nuevo ticket',
    'ticket_title': 'Titulo',
    'choose_file_button': 'Elige un archivo',
    'ticket_file_label': 'Archivo',
    'selected_file': 'Archivo seleccionado',
    'selected_files': 'Archivos seleccionados',
    'cancel_button': 'Cancelar',
    'change_pass': 'Cambiar contraseña',
    'current_pass': 'Contraseña actual',
    'new_pass': 'Nueva contraseña',
    'change_pass_txt': 'La contraseña debe contener las siguientes características',
    'pass_char_length': '8 digitos',
    'pass_char_caps': 'Contener al menos una mayuscula',
    'pass_digit': 'Contener un número',
    'pass_symbol': 'Contener un simbolo',
    'support_button_menu': 'Soporte',
    'new_ticket_button': 'Nuevo ticket',
    'uploading_label': 'Cargando',
    'file_label': 'Archivo',
    'complete_label': 'Completado'
  },
  es: {
    'close_button': 'Cerrar',
    'date_label': 'Fecha',
    'ticket_type': 'Tipo de Ticket',
    'ticket_afectation': 'Afectación',
    'owner_id': 'ID del propietario',
    'ticket_files': 'Archivos',
    'db_status_label': 'Estatus',
    'customer_service_button_menu': 'Servicio al cliente',
    'new_comment_label': 'Nuevo comentario',
    'file_attached': 'Archivo adjunto',
    'ticket_history': 'Historial',
    'ticket_time': 'Hora',
    'send_button': 'Enviar',
    'table_head_cat': 'Categoría',
    'table_head_revenue_average': 'Promedio de ingresos',
    'table_head_gross_profit_average': 'Promedio de ganancia bruta',
    'categories_button_menu': 'Categorías',
    'cat_revenue_average': 'Promedio de ingresos',
    'cat_gross_profit_average': 'Promedio de ganancia bruta',
    'cat_table_title': 'Categoría',
    'table_head_sku': 'SKU',
    'table_head_store': 'Sucursal',
    'products_button_menu': 'Productos',
    'prod_gross_profit_average': 'Promedio de ganancia bruta',
    'prod_revenue_average': 'Promedio de ingresos',
    'prod_table_title': 'Producto',
    'manage_button': 'Administrar',
    'confirm_button': 'Confirmar',
    'stores_button_menu': 'Sucursales',
    'stores_gross_profit_average': 'Promedio de ganancia bruta',
    'stores_revenue_average': 'Promedio de ingresos',
    'table_title': 'Sucursal',
    'store_A': 'Sucursal A',
    'store_B': 'Sucursal B',
    'gross_profit_average': 'Promedio de ganancia bruta',
    'revenue_average': 'Promedio de ingresos',
    'categories_button': 'Categorías',
    'top_products_by_category': 'Top productos por categoría',
    'products_button': 'Productos',
    'product': 'producto',
    'table_company': 'Compañia',
    'table_price': 'Precio',
    'table_prev_price': 'Precio previo',
    'table_diff': 'Diferencia',
    'table_variable': 'Variable',
    'table_current': 'Actual',
    'table_simulation': 'Simulación',
    'table_current_price': 'Precio actual',
    'table_max_price': 'Precio máximo',
    'table_min_price': 'Precio mínimo',
    'table_current_cost': 'Costo actual',
    'demand_at': 'Demanda en',
    'save_button': 'Guardar',
    'empty_forecasts': 'Forecasts vacio',
    'empty_search_label': 'Busqueda vacia',
    'delete_selection': 'Borrar selección',
    'search_by_forecast': 'Buscar por forecast',
    'new_forecast_label': 'Nuevo Forecast',
    'form_subject': 'Asunto',
    'form_desc': 'Descripción',
    'new_ticket': 'nuevo ticket',
    'ticket_title': 'Titulo',
    'choose_file_button': 'Elige un archivo',
    'ticket_file_label': 'Archivo',
    'selected_file': 'Archivo seleccionado',
    'selected_files': 'Archivos seleccionados',
    'cancel_button': 'Cancelar',
    'change_pass': 'Cambiar contraseña',
    'current_pass': 'Contraseña actual',
    'new_pass': 'Nueva contraseña',
    'change_pass_txt': 'La contraseña debe contener las siguientes características',
    'pass_char_length': '8 digitos',
    'pass_char_caps': 'Contener al menos una mayuscula',
    'pass_digit': 'Contener un número',
    'pass_symbol': 'Contener un simbolo',
    'support_button_menu': 'Soporte',
    'new_ticket_button': 'Nuevo ticket',
    'uploading_label': 'Cargando',
    'file_label': 'Archivo',
    'complete_label': 'Completado'
  }
};